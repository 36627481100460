import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';


export default function Ask() {
    const [selectedValue,setSelectedValue] = useState('');

    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault();
        try {
            if (selectedValue === '1') {
                navigate('/creditrepair')
            }else if (selectedValue === '2') {
                navigate('/loan')
            }else if (selectedValue === '3') {
                navigate('/personal')
            }else if (selectedValue === '4') {
                navigate('/upload')
            }else if (selectedValue === '5') {
                navigate('/student')
            }else if (selectedValue === '6') {
                navigate('/claim')
            }else {
                navigate('/ask')
            }
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100vh',width:'100vw',backgroundColor:'#fff'}}>
        <h1>How Can I Help You?</h1>
       <form onSubmit={handleSubmit}>
      <div className='form-group mb-3'>
      <Form.Select 
       aria-label="Default select example" 
       onChange={(e) => setSelectedValue(e.target.value)}
       required>
        <option>Choose option</option>
        <option value="1">Credit repair service</option>
        <option value="2">Business loan</option>
        <option value="3">Personal loan</option>
        <option value="4">Upload Documents</option>
        <option value="5">Student Application Form</option>
        <option value="6">Worker's Compensation</option>
        </Form.Select>
      </div>

        <div className='form-group mb-3'>
        <button>Continue</button>
        </div>
       </form>

    </div>
  )
}
