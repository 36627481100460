import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import './App.css';
import CreditRepair from './components/CreditRepair';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import './firebase';
import Terms from './Terms';
import Privacy from './Privacy';
import FreeLoan from './components/FreeLoan';
import Success from './components/Success';
import About from './components/About';
import Student from './components/Student';
import CreditRepairApps from './components/CreditRepairApps';
import FreeLoanApps from './components/FreeLoanApps';
import PersonalLoan from './components/PersonalLoan';
import PersonalLoanApps from './components/PersonalLoanApps';
import ContactForm from './components/ContactForm';
import ContactedUsers from './components/ContactedUsers';
import CreditRepairSuccess from './components/CreditRepairSuccess';
import CreditRepairSelfService from './components/CreditRepairSelfService';
import StudentsData from './components/StudentsData';
import Claim from './components/Claim';
import Claimapps from './components/Claimapps';
import MainHome from './components/MainHome';
import Company from './components/Company';
import Companyapps from './components/Companyapps';
import Tickets from './components/Tickets';
import UploadDocument from './components/UploadDocument';
import UploadedDocuments from './components/UploadedDocuments';
import Account from './Account';
import OnboardSuccess from './OnboardSuccess';
import OnboardRefresh from './OnboardRefresh';
import Order from './components/Order';
import PaymentLinkSuccess from './components/PaymentLinkSuccess';
import PaymentInfo from './components/PaymentInfo';
import PaymentInfos from './components/PaymentInfos';
import Consultation from './components/Consultation';
import SSN from './components/SSN';
import SSNInfos from './components/SSNInfos';
import Tourist from './components/Tourist';
import Touristapps from './components/Touristapps';
import Consultationapps from './components/Consultationapps';
import Links from './components/Links';
import CreditRepair400 from './components/CreditRepair400';
import CreateAccount from './components/CreateAccount';
import CreateAccountDocs from './components/CreateAccountDocs';
import CreditRepair350 from './components/CreditRepair350';
import PaymentApp from './Stripe/PaymentApp';
import Transfer from './Stripe/Transfer';
import Transferx from './StripeX/Transferx';
import PaymentSuccess from './StripeX/PaymentSuccess';
import Transferapps from './StripeX/Transferapps';
import Calculator from './StripeX/Calculator';
import CreditRepairSubscription from './components/CreditRepairSubscription';
import CreditRepairSubscriptionapps from './components/CreditRepairSubscriptionapps';
import CreditReport from './components/CreditReport';
import HomePage from './components/HomePage';
import Services from './components/Services';
import Ask from './components/Ask';


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/creditrepair",
    element: <CreditRepair />,
  },
  {
    path:'/terms',
    element:<Terms />
  },
  {
    path:'/privacy',
    element:<Privacy />
  },
  {
    path:'/loan',
    element:<FreeLoan />
  },
  {
    path:'/success',
    element:<Success />
  },
  {
    path:'/about',
    element:<About />
  },
  {
    path:'/student',
    element:<Student />
  },
  {
    path:'/studentapps',
    element:<StudentsData />
  },
  {
    path:'/creditapps',
    element:<CreditRepairApps />
  },
  {
    path:'/loanapps',
    element:<FreeLoanApps />
  },
  {
    path:'/personal',
    element:<PersonalLoan />
  },
  {
    path:'/personalapps',
    element:<PersonalLoanApps />
  },
  {
    path:'/contact',
    element:<ContactForm />
  },
  {
    path:'/contacts',
    element:<ContactedUsers />
  },
  {
    path:'/credit-repair-success',
    element:<CreditRepairSuccess />
  },
  {
    path:'/credit-repair-self-service',
    element:<CreditRepairSelfService />
  },
  {
    path:'/students',
    element:<StudentsData />
  },
  {
    path:'/transferx',
    element:<Transfer />
  },
  {
    path:'/sendmoney/:id',
    element:<PaymentApp />
  },
  {
    path:'/claim',
    element:<Claim />
  },
  {
    path:'/claimapps',
    element:<Claimapps />
  },
  {
    path:'/company',
    element:<Company />
  },
  {
    path:'/companyapps',
    element:<Companyapps />
  },
  {
    path:'/ticket',
    element:<Tickets />
  },
  {
    path:'/upload',
    element:<UploadDocument />
  },
  {
    path:'/uploadapps',
    element:<UploadedDocuments />
  },
  {
    path: "/account/:id",
    element: <Account />,
  },
  {
    path:'/onboarding-success',
    element:<OnboardSuccess />
  },
  {
    path:'/onboarding-refresh',
    element:<OnboardRefresh />
  },
  {
    path:'/order',
    element:<Order />
  },
  {
    path:'/success/:id',
    element:<PaymentLinkSuccess />
  },
  {
    path:'/payment-info',
    element:<PaymentInfo />
  },
  {
    path:'/payment-infos',
    element:<PaymentInfos />
  },
  {
    path:'/consultation',
    element:<Consultation />
  },
  {
    path:'/consultationapps',
    element:<Consultationapps />
  },
  {
    path:'/ssn',
    element:<SSN />
  },
  {
    path:'/ssnapps',
    element:<SSNInfos />
  },
  {
    path:'/tourist',
    element:<Tourist />
  },
  {
    path:'/touristapps',
    element:<Touristapps />
  },
  {
    path:'/links',
    element:<Links />
  },
  {
    path:'/creditrepair400',
    element:<CreditRepair400 />
  },
  {
    path:'/creditrepair350',
    element:<CreditRepair350 />
  },
  {
    path:'/account',
    element:<CreateAccount />
  },
  {
    path:'/accountapps',
    element:<CreateAccountDocs />
  },
  {
    path:'/transfer',
    element:<Transferx />
  },
  {
    path:'/payment-success',
    element:<PaymentSuccess />
  },
  {
    path:'/transferapps',
    element:<Transferapps />
  },
  {
    path:'/calculator',
    element:<Calculator />
  },
  {
    path:'/subscription',
    element:<CreditRepairSubscription />
  },
  {
    path:'/subscriptionapps',
    element:<CreditRepairSubscriptionapps />
  },
  {
    path:'/CreditReport',
    element:<CreditReport/>
  },
  {
    path:'/services',
    element:<Services />
  },
  {
    path:'/ask',
    element:<Ask />
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
