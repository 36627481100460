export const corporation = {
    name:'Aloqa Corp',
    address:`5940 S Rainbow Blvd, Las Vegas, Nevada 89118-2507 US`,
    ein:`99-3284546`,
    stateId:`E40945982024-9`,
    phone:'+1-929-473-0052',
    email:`aloqacorp@gmail.com`,
    about:`Consulting firm`
};

export const s3ImageUri = `https://d3hyw1bllge1yx.cloudfront.net/`;